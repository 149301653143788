import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/Layout/Layout"
import Content, { HTMLContent } from "../components/Content"
import Heading from "../components/Heading/Heading"
import useSiteMetadata from "../components/SiteMetadata"


export const GenericPageTemplate = ({
  content,
  contentComponent,
  title,
  subtitle,
  helmet
}) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ""}
      <div className="section__content section__content--text">
        <Heading level={1} modifiers={["major", "centred"]}>{title}</Heading>
        <Heading level={0} modifiers={["minor", "centred"]}>{subtitle}</Heading>
        <PageContent className="cms-content cms-content--generic" content={content}/>
      </div>
    </section>
  )
}

GenericPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  helmet: PropTypes.object,
}

const GenericPage = ({ data }) => {
  const { markdownRemark: page } = data
  const { siteUrl } = useSiteMetadata()

  let ogImage = null

  if (page.frontmatter.ogImage){
    ogImage = page.frontmatter.ogImage.childImageSharp.gatsbyImageData
  }

  return (
    <Layout>
      <GenericPageTemplate
        content={page.html}
        contentComponent={HTMLContent}
        title={page.frontmatter.title}
        subtitle={page.frontmatter.subtitle}
        helmet={
          <Helmet titleTemplate="%s | Kingston Labs">
            <title>{page.frontmatter.title}</title>
            <meta
              property="og:title"
              content={`${page.frontmatter.title}`}
            />
            <meta
              name="description"
              content={`${page.frontmatter.description}`}
            />
            <meta
              property="og:description"
              content={`${page.frontmatter.description}`}
            />
            {ogImage ? <meta property="og:image" content={`${siteUrl}${ogImage.src}`}/> : ""}
            {ogImage ? <meta property="og:image:height" content={ogImage.height}/> : ""}
            {ogImage ? <meta property="og:image:width" content={ogImage.width}/> : ""}
            <meta property="og:url" content={`${siteUrl}${page.fields.slug}`}/>
          </Helmet>
        }
      />
    </Layout>
  )
}

GenericPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GenericPage

export const GenericPageQuery = graphql`query GenericPage($id: String!) {
  markdownRemark(id: {eq: $id}) {
    html
    fields {
      slug
    }
    frontmatter {
      title
      subtitle
      description
      ogImage: image {
        childImageSharp {
          gatsbyImageData(width: 1200, quality: 100, layout: FIXED)
        }
      }
    }
  }
}`
